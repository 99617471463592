import React, { FC } from 'react'
import styles from '@styles/components/Reactions.module.scss'
import cn from 'classnames'
import ReactionsCount from '@components/reactions/ReactionsCount'
import ReactionsReactAction from '@components/reactions/ReactionsReactAction'

export type ReactionType = 'like' | 'mindblown' | 'celebrate' | 'love'

export const ReactionIconMap: Record<ReactionType, () => string> = {
  like: () => '👍',
  mindblown: () => '🤯',
  celebrate: () => '👏',
  love: () => '❤️'
}

interface Props {
  projectUuid: string
  userUuid?: string | null
  totalReactions: number
  userReactedWith?: string
  uniqueReactionTypes: ReactionType[]
}

const Reactions: FC<Props> = ({
  projectUuid,
  userUuid,
  totalReactions,
  userReactedWith,
  uniqueReactionTypes
}) => {
  return (
    <div className={styles.container}>
      <div
        className={cn(styles['count'], {
          [styles['counts']]: totalReactions > 1
        })}>
        <ReactionsCount
          totalReactions={totalReactions}
          uniqueReactionTypes={uniqueReactionTypes}
        />
      </div>
      <ReactionsReactAction
        userReactedWith={userReactedWith}
        projectUuid={projectUuid}
        userUuid={userUuid}
        totalReactions={totalReactions}
      />
    </div>
  )
}

export default Reactions
