import React, { FC, useMemo, useRef, useState } from 'react'
import { Icon, Overlay, Popover } from 'ui'
import styles from '@styles/components/ReactionsReactAction.module.scss'
import AddSmileIcon from 'public/add-smile-temp-icon.svg'
import cn from 'classnames'
import {
  useUpdateReactionMutation,
  useCreateReactionMutation,
  useDeleteReactionMutation
} from '@store/graphql/__generated__/schema'
import { ReactionIconMap, ReactionType } from './Reactions'

import { useGetReactionsQuery } from '@store/graphql/__generated__/schema'
import ReactionsReactors from '@components/reactions/ReactionsReactors'

interface Props {
  userReactedWith?: string
  projectUuid: string
  userUuid?: string | null
  totalReactions: number
}

const ReactionsReactAction: FC<Props> = ({
  userReactedWith,
  projectUuid,
  userUuid,
  totalReactions
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [shouldShowOverlay, setShouldShowOverlay] = useState(false)
  const [hoveredReaction, setHoveredReaction] = useState<ReactionType | null>(
    null
  )
  const timeout = useRef<NodeJS.Timeout>()

  const [updateReaction, { loading: loadingUpdateReaction }] =
    useUpdateReactionMutation({
      fetchPolicy: 'network-only'
    })
  const [createReaction, { loading: loadingCreateReaction }] =
    useCreateReactionMutation({
      fetchPolicy: 'network-only'
    })
  const [deleteReaction, { loading: loadingDeleteReaction }] =
    useDeleteReactionMutation({
      fetchPolicy: 'network-only'
    })

  const isActionDisabled =
    loadingUpdateReaction || loadingCreateReaction || loadingDeleteReaction

  const triggerButtonId = `trigger-reaction-react-${projectUuid}`

  const delayPopoverClose = () => {
    timeout.current = setTimeout(() => {
      setIsPopoverOpen(false)
      setHoveredReaction(null)
      getReactions.refetch()
    }, 300)
  }

  const showPopover = () => {
    setIsPopoverOpen(true)
    timeout.current && clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      if (!isPopoverOpen) {
        setIsPopoverOpen(false)
        setHoveredReaction(null)
      }
    }, 1200)
  }

  const getReactions = useGetReactionsQuery({
    variables: { projectUuid, userUuid },
    skip: !isPopoverOpen
  })

  const currentReaction =
    getReactions?.data?.reactions?.userReaction?.reactionType

  const handleSelectedReaction = async (selectedReaction: ReactionType) => {
    if (!userUuid || isActionDisabled) return

    const variables = {
      userUuid,
      projectUuid,
      reactionType: selectedReaction
    }

    try {
      if (userHasReactedToProject && selectedReaction === currentReaction) {
        await deleteReaction({ variables })
      } else if (userHasReactedToProject) {
        await updateReaction({ variables })
      } else {
        await createReaction({ variables })
      }

      await getReactions.refetch()
    } catch (error) {
      console.error('Error updating reaction', error)
    }
  }

  const isReactionType = (key: any): key is ReactionType =>
    Object.keys(ReactionIconMap).includes(key)

  const reactionsCountMap = useMemo(() => {
    return getReactions?.data?.reactions?.uniqueReactions?.reduce(
      (acc, reaction) => {
        if (reaction?.reactionType && isReactionType(reaction?.reactionType)) {
          acc[reaction.reactionType] = reaction.total ?? 0
        }
        return acc
      },
      {} as Record<ReactionType, number>
    )
  }, [getReactions?.data?.reactions?.uniqueReactions])

  const handleShowMore = () => {
    if (!getReactions?.data?.reactions?.metadata?.after) {
      return
    }

    getReactions.fetchMore({
      variables: {
        projectUuid,
        cursorAfter: getReactions?.data?.reactions?.metadata?.after,
        reactionType: hoveredReaction
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev
        }

        return {
          reactions: {
            ...fetchMoreResult.reactions,
            entries: [
              ...(prev.reactions?.entries || []),
              ...(fetchMoreResult.reactions?.entries || [])
            ]
          }
        }
      }
    })
  }

  const hasMultipleReactions = totalReactions > 1
  const userHasReactedToProject = isReactionType(userReactedWith)

  const iconWrapperClasses = cn(styles['icon-wrapper'], {
    [styles['has-reacted-icon-wrapper']]: userHasReactedToProject,
    [styles['multiple-reactions-wrapper']]:
      (userHasReactedToProject && hasMultipleReactions) ||
      (!userHasReactedToProject && hasMultipleReactions)
  })

  const formatReactionCount = (count: number | undefined) => {
    if (count === undefined) {
      return ''
    }
    if (count > 10) {
      return '10+'
    } else {
      return count.toString()
    }
  }

  const onHoveredReaction = (reactionType: ReactionType) => {
    setHoveredReaction(reactionType)
    getReactions.refetch({ userUuid, projectUuid, reactionType })
  }

  return (
    <div onMouseEnter={() => setShouldShowOverlay(true)}>
      <span
        role="button"
        id={triggerButtonId}
        className={styles['trigger-button']}
        onClick={() => {
          showPopover()
        }}>
        <div
          data-testid={`reaction-icon-${userReactedWith}`}
          className={iconWrapperClasses}>
          {userHasReactedToProject ? (
            ReactionIconMap[userReactedWith as ReactionType]()
          ) : (
            <Icon slot="icon" className="icon-m">
              <AddSmileIcon />
            </Icon>
          )}
        </div>
        {/* {hasMultipleReactions && <div className={styles['icon-wrapper']} />} */}
      </span>

      {/** we want to defer the loading of this component until it's required: https://nextjs.org/learn-pages-router/seo/improve/dynamic-import-components
       */}
      {shouldShowOverlay && (
        <Overlay
          placement="top"
          type="manual"
          trigger={triggerButtonId}
          open={isPopoverOpen}
          spClosed={() => setIsPopoverOpen(false)}>
          <Popover
            className={styles['popover']}
            onMouseLeave={delayPopoverClose}
            onMouseEnter={() =>
              timeout.current && clearTimeout(timeout.current)
            }>
            <div className={styles['reactions']}>
              {Object.keys(ReactionIconMap).map(reaction => {
                const reactionCount =
                  reactionsCountMap?.[reaction as ReactionType]

                return (
                  <div className={styles['reaction-wrapper']} key={reaction}>
                    <div
                      role="button"
                      className={cn(styles['reaction'], {
                        [styles['selected']]: currentReaction === reaction
                      })}
                      onClick={() =>
                        handleSelectedReaction(reaction as ReactionType)
                      }
                      onMouseEnter={() => {
                        onHoveredReaction(reaction as ReactionType)
                      }}>
                      {ReactionIconMap[reaction as ReactionType]()}
                    </div>
                    <span className={styles['reaction-count']}>
                      {formatReactionCount(reactionCount)}
                    </span>
                  </div>
                )
              })}
            </div>
          </Popover>
          {isPopoverOpen &&
            hoveredReaction &&
            (reactionsCountMap?.[hoveredReaction] ?? 0) > 0 && (
              <ReactionsReactors
                onMouseEnter={() => {
                  timeout.current && clearTimeout(timeout.current)
                }}
                onMouseLeave={() => {
                  delayPopoverClose()
                }}
                showMoreReactions={handleShowMore}
                reactions={getReactions?.data?.reactions?.entries || []}
                nextOffset={getReactions?.data?.reactions?.metadata?.after}
              />
            )}
        </Overlay>
      )}
    </div>
  )
}

export default ReactionsReactAction
