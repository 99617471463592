import {
  ComponentProps,
  FC,
  Key,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useRouter } from 'next/router'
import {
  ActionMenu,
  Button,
  Dialog,
  DialogBase,
  Icon,
  MenuItem,
  Overlay,
  Switch,
  Textfield,
  Theme,
  Toast
} from 'ui'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import cn from 'classnames'
// @ts-ignore
import Avatar from '@react/react-spectrum/Avatar'
import styles from '@styles/components/ProjectCard.module.scss'
import {
  Project,
  User,
  useDuplicateProjectMutation,
  useGetUserByUuidLazyQuery,
  useUpdateProjectMutation
} from '@store/graphql/__generated__/schema'
import dayjs from 'dayjs'
import { useAuth } from '@hooks/useAuth'
import Reactions, { ReactionType } from '@components/reactions/Reactions'
import ImgPlaceHolderIcon from '/public/img_placeholder.svg'
import CopyLinkIcon from '/public/s2_icon_copy.svg'
import ClockPendingIcon from '/public/s2_icon_clock_pending.svg'
import UserIcon from '/public/s2_icon_user.svg'
import DeleteIcon from '/public/s2_icon_trash.svg'
import DrawIcon from '/public/s2_icon_draw.svg'
import DuplicateIcon from '/public/s2_icon_duplicate.svg'
import StarIcon from '/public/s2_icon_star.svg'
import ArchiveIcon from '/public/s2_icon_archive.svg'
import { getProjectCardHeightFromDocumentFrameSize } from './ProjectCardSize'

export interface ProjectCardProps {
  createdAt: Project['insertedAt']
  name: Project['name']
  imageUrl: Project['imageUrl']
  uuid: Project['uuid']
  public: Project['public']
  featured: Project['featured']
  documentFrameSize: Project['documentFrameSize']
  editingEnabled?: boolean
  totalReactions?: Project['totalReactions']
  userReactedWith?: Project['userReactedWith']
  uniqueReactionTypes: Project['uniqueReactionTypes']
  adminEditingEnabled?: boolean
  showShareBtn?: boolean
  showReactions?: boolean
  projectCreatorUuid: User['uuid']
  getCachedCardHeight?: (uuid: string, documentFrameSize: any) => number
}

type ProjectCardActionMenuProps = Omit<
  ComponentProps<typeof ActionMenu>,
  'className'
> & { showProjectCardOverlay: boolean }

const ProjectCardActionMenu = memo(
  ({
    children,
    showProjectCardOverlay,
    ...props
  }: PropsWithChildren<ProjectCardActionMenuProps>) => {
    return (
      <ActionMenu
        {...props}
        className={cn(
          styles['action-menu'],
          showProjectCardOverlay && styles['action-menu__hover']
        )}>
        {children}
      </ActionMenu>
    )
  }
)

const menuItems = [
  {
    key: 'duplicate',
    label: 'Duplicate',
    icon: <DuplicateIcon />
  },
  {
    key: 'archive',
    label: 'Delete',
    icon: <DeleteIcon />
  },
  {
    key: 'edit project name',
    label: 'Edit project name',
    icon: <DrawIcon />
  }
]

const ProjectCard: FC<ProjectCardProps> = ({
  createdAt,
  name,
  imageUrl,
  uuid,
  public: projectPublic,
  featured,
  documentFrameSize,
  editingEnabled,
  totalReactions,
  userReactedWith,
  uniqueReactionTypes,
  adminEditingEnabled,
  showShareBtn,
  showReactions,
  projectCreatorUuid,
  getCachedCardHeight
}) => {
  const router = useRouter()
  const { localUser } = useAuth()

  const [showNameInput, setShowNameInput] = useState(false)
  const [projectInputValue, setProjectInputValue] = useState(name)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [errorLoadingImage, setErrorLoadingImage] = useState(false)
  const [showDeleteWarningDialog, setShowDeleteWarningDialog] = useState(false)
  const [shouldShowOverlay, setShouldShowOverlay] = useState(false)
  const [shouldShowDialog, setShouldShowDialog] = useState(false)
  const [
    shouldLoadDynamicComponentOnHover,
    setShouldLoadDynamicComponentOnHover
  ] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [
    alreadyFetchedProjectCreatorData,
    setAlreadyFetchedProjectCreatorData
  ] = useState(false)
  const [showProjectActionMenu, setShowProjectActionMenu] =
    useState<boolean>(false)
  const [showProjectCardOverlay, setShowProjectCardOverlay] =
    useState<boolean>(false)

  const currentUserIsProjectCreator = localUser?.uuid === projectCreatorUuid

  const [updateProject] = useUpdateProjectMutation()
  const [duplicateProject] = useDuplicateProjectMutation()

  const [queryProjectCreatorData, { data: projectCreatorData }] =
    useGetUserByUuidLazyQuery()

  const fetchProjectCreatorData = useCallback(() => {
    if (alreadyFetchedProjectCreatorData) return
    queryProjectCreatorData({
      fetchPolicy: 'cache-and-network',
      onCompleted: () => setAlreadyFetchedProjectCreatorData(true),
      onError: () => setAlreadyFetchedProjectCreatorData(false),
      variables: { uuid: projectCreatorUuid! }
    })
  }, [projectCreatorUuid, alreadyFetchedProjectCreatorData])

  const normalizedFrameDimensionHeight = useMemo(
    () => ({
      height:
        typeof getCachedCardHeight === 'function'
          ? getCachedCardHeight(uuid as string, documentFrameSize)
          : getProjectCardHeightFromDocumentFrameSize(
              documentFrameSize,
              uuid as string
            )
    }),
    [getCachedCardHeight, documentFrameSize, uuid]
  )

  const projectCreatedDate = useMemo(
    () => `${dayjs(createdAt).fromNow()}`,
    [createdAt]
  )

  const toggleProjectPublic = useCallback(() => {
    return updateProject({
      variables: {
        uuid: uuid as string,
        public: !projectPublic
      }
    })
  }, [uuid, projectPublic])

  const toggleProjectFeatured = useCallback(() => {
    return updateProject({
      variables: {
        uuid: uuid as string,
        featured: !featured
      }
    })
  }, [uuid, featured])

  const archiveProject = useCallback(() => {
    updateProject({
      variables: {
        uuid: uuid as string,
        archived: true
      }
    })
    setShowDeleteWarningDialog(false)
  }, [uuid])

  const handleMenuItemSelection = useCallback(
    (key: Key) => {
      switch (key) {
        case 'duplicate':
          return duplicateProject({ variables: { uuid: uuid as string } })
        case 'archive':
          return openDeleteWarningDialog()
        case 'edit project name':
          return openNameInput()
        default:
          return null
      }
    },
    [uuid]
  )

  const openNameInput = useCallback(() => setShowNameInput(true), [])

  const handleInput = useCallback(e => {
    const value = e.currentTarget.value
    setProjectInputValue(value)
  }, [])

  const handleChange = useCallback(
    e => {
      const value = e.currentTarget.value
      if (projectInputValue !== name) {
        updateProject({
          variables: {
            uuid: uuid as string,
            name: value
          }
        })
      }

      setShowNameInput(false)
    },
    [updateProject, projectInputValue, name]
  )

  const openDeleteWarningDialog = useCallback(
    () => setShowDeleteWarningDialog(true),
    []
  )

  const closeDeleteWarningDialog = useCallback(
    () => setShowDeleteWarningDialog(false),
    []
  )

  const copyProjectLinkToClipboard = useCallback(() => {
    navigator.clipboard.writeText(`${window.location.origin}/studio/${uuid}`)
  }, [])

  const navigateToUserPage = useCallback(
    () => router.push(`/profile/${projectCreatorUuid}`),
    []
  )

  const toggleProjectActionMenu = useCallback(
    () => setShowProjectActionMenu(!showProjectActionMenu),
    [showProjectActionMenu]
  )

  const preloadOverlayComponent = useCallback(() => {
    setShouldShowOverlay(true)
    setShouldLoadDynamicComponentOnHover(true)
  }, [])

  const preloadDialogComponent = useCallback(
    () => setShouldShowDialog(true),
    []
  )

  const handleMouseEnter = useCallback(() => setIsHovered(true), [])
  const handleMouseLeave = useCallback(() => setIsHovered(false), [])

  const onImageLoad = useCallback(() => setImageLoaded(true), [])
  const onImageLoadErr = useCallback(() => setErrorLoadingImage(true), [])

  const onOutsideUnderlayClick = useCallback(() => {
    setShowProjectActionMenu(false)
    setShowProjectCardOverlay(false)
  }, [])

  const openNameEditor = useCallback(() => {
    if (currentUserIsProjectCreator && editingEnabled) {
      openNameInput()
    }
  }, [currentUserIsProjectCreator, editingEnabled])

  const openStudio = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    if (event.target === event.currentTarget) router.push(`/studio/${uuid}`)
  }

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (isHovered) {
      // delay the hover to prevent accidental loading of the dynamic component while scrolling
      timer = setTimeout(() => {
        setShouldLoadDynamicComponentOnHover(true)
      }, 500)
      setShowProjectCardOverlay(true)
      fetchProjectCreatorData()
    } else {
      setShowProjectCardOverlay(false)
    }
    return () => clearTimeout(timer)
  }, [isHovered])

  useEffect(() => {
    if (name !== projectInputValue && !showNameInput) setProjectInputValue(name)
  }, [projectInputValue, name, showNameInput])

  return (
    <div data-testid="project-card">
      {/* This is to detect outside clicks. It's needed because we need a way to detect if a user
    has clicked outside of an ActionMenu to close it. Unfortunately, Spectrum does not provide
    an API for this */}
      {showProjectActionMenu && (
        <div
          className={styles['outside-click-underlay']}
          onClick={onOutsideUnderlayClick}
        />
      )}
      <a
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        href={`/studio/${uuid}`}
        onClick={openStudio}
        data-testid="project-card-container"
        className={styles['container']}>
        <div className={styles['project-card']}>
          <div
            className={cn(
              styles['project-card-overlay'],
              (showProjectCardOverlay || showNameInput) &&
                styles['project-card-overlay__hover']
            )}
          />
          <div
            style={normalizedFrameDimensionHeight}
            className={styles['project-card__img-container']}>
            <div
              className={cn(
                styles['project-card__content'],
                (showProjectCardOverlay || showNameInput) &&
                  styles['project-card__content__hover']
              )}>
              <div className={styles['project-card__project-title-container']}>
                {projectCreatorData?.userByUuid?.adobeUserAvatarUrl && (
                  <div>
                    <Avatar
                      className={styles['project-card__avatar']}
                      src={projectCreatorData?.userByUuid?.adobeUserAvatarUrl}
                      alt={
                        projectCreatorData.userByUuid.adobeUserDisplayName
                          ? `${projectCreatorData.userByUuid.adobeUserDisplayName}'s avatar`
                          : 'User avatar'
                      }
                    />
                  </div>
                )}

                <div>
                  <div>
                    {!showNameInput ? (
                      <>
                        {imageLoaded || errorLoadingImage ? (
                          <div
                            data-testid="project-name-container"
                            className={styles['project-card__project-title']}
                            onClick={openNameEditor}>
                            {projectInputValue}
                          </div>
                        ) : (
                          <Skeleton
                            height={20}
                            width={70}
                            baseColor="#e6e6e6"
                            highlightColor="#f2f2f2bf"
                            style={{ marginBottom: 3 }}
                          />
                        )}
                      </>
                    ) : (
                      <Textfield
                        style={{ pointerEvents: 'all', width: 180 }}
                        autofocus
                        input={handleInput}
                        change={handleChange}
                        value={projectInputValue}
                        onBlur={handleChange}
                      />
                    )}
                  </div>
                  <div className={styles['project-card__display-name']}>
                    by{' '}
                    {projectCreatorData?.userByUuid?.adobeUserDisplayName ||
                      'User'}
                  </div>
                </div>
              </div>
              <div className={styles['project-card__created-at']}>
                <ClockPendingIcon />
                {projectCreatedDate}
              </div>
            </div>

            {(!imageLoaded || errorLoadingImage) && (
              <div className={styles['img-placeholder']}>
                <div className={styles['img-placeholder-content']}>
                  {errorLoadingImage ? (
                    <ImgPlaceHolderIcon />
                  ) : (
                    <div
                      className={styles['img-placeholder-skeleton-container']}>
                      <Skeleton
                        height={normalizedFrameDimensionHeight.height}
                        width="100%"
                        baseColor="#e6e6e6"
                        highlightColor="#f2f2f2bf"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            <img
              data-testid={`project-card-img-${name}`}
              className={styles['project-card__img']}
              onLoad={onImageLoad}
              onError={onImageLoadErr}
              src={`${process.env.NEXT_PUBLIC_SERVICE_CORE_STORAGE}${imageUrl}`}
              alt={name}
              aria-label={name}
            />
          </div>
        </div>

        {adminEditingEnabled && shouldLoadDynamicComponentOnHover && (
          <div
            className={cn(
              styles['action-menu-container'],
              styles['action-menu-container-left-position']
            )}>
            <ProjectCardActionMenu
              onClick={toggleProjectActionMenu}
              showProjectCardOverlay={showProjectCardOverlay}>
              <MenuItem>
                <Switch
                  checked={featured}
                  onClick={toggleProjectFeatured}
                  emphasized>
                  <Icon slot="icon" className="icon-m">
                    <StarIcon />
                  </Icon>
                  Featured
                </Switch>
              </MenuItem>
              <MenuItem
                onMouseEnter={preloadDialogComponent}
                onClick={openDeleteWarningDialog}>
                <Icon slot="icon" className="icon-m">
                  <ArchiveIcon />
                </Icon>
                Archive
              </MenuItem>
            </ProjectCardActionMenu>
          </div>
        )}

        {showShareBtn &&
          !showNameInput &&
          shouldLoadDynamicComponentOnHover && (
            <div
              className={styles['action-menu-container']}
              onMouseEnter={preloadOverlayComponent}>
              <>
                <Theme theme="express" color="light">
                  <ProjectCardActionMenu
                    onClick={toggleProjectActionMenu}
                    showProjectCardOverlay={showProjectCardOverlay}>
                    <MenuItem
                      onClick={copyProjectLinkToClipboard}
                      id={`share-trigger-${uuid}`}>
                      <Icon slot="icon" className="icon-m">
                        <CopyLinkIcon />
                      </Icon>
                      Copy link
                    </MenuItem>
                    <MenuItem onClick={navigateToUserPage}>
                      <Icon slot="icon" className="icon-m">
                        <UserIcon />
                      </Icon>
                      More from this user
                    </MenuItem>
                  </ProjectCardActionMenu>

                  {shouldShowOverlay && (
                    <Overlay
                      trigger={`share-trigger-${uuid}@click`}
                      type="manual">
                      <Toast
                        variant="positive"
                        className={styles['toast']}
                        timeout={1500}>
                        Link copied
                      </Toast>
                    </Overlay>
                  )}
                </Theme>
              </>
            </div>
          )}

        {currentUserIsProjectCreator &&
          editingEnabled &&
          !showNameInput &&
          shouldLoadDynamicComponentOnHover && (
            <div className={styles['action-menu-container']}>
              <ProjectCardActionMenu
                onClick={toggleProjectActionMenu}
                showProjectCardOverlay={showProjectCardOverlay}>
                <MenuItem>
                  <Switch
                    checked={projectPublic}
                    onClick={toggleProjectPublic}
                    emphasized>
                    Project visible{' '}
                    {projectPublic ? 'to everyone' : 'only to you'}
                  </Switch>
                </MenuItem>
                {menuItems.map((item, i) => (
                  <MenuItem
                    key={i}
                    onMouseEnter={() => {
                      if (item.key === 'archive') {
                        preloadDialogComponent()
                      }
                    }}
                    onClick={() => {
                      handleMenuItemSelection(item.key)
                    }}>
                    <Icon slot="icon" className="icon-m">
                      {item.icon}
                    </Icon>
                    {item.label}
                  </MenuItem>
                ))}
              </ProjectCardActionMenu>
            </div>
          )}

        {shouldShowDialog && (
          <DialogBase
            underlay
            open={showDeleteWarningDialog}
            className={styles['delete-warning-dialog-container']}>
            <Dialog size="s">
              <h2 slot="heading">Delete project?</h2>
              <p>Are you sure you want to delete your project?</p>
              <div className={styles['delete-warning-dialog-footer']}>
                <Button
                  variant="secondary"
                  treatment="outline"
                  onClick={closeDeleteWarningDialog}>
                  Cancel
                </Button>
                <Button
                  variant="negative"
                  treatment="fill"
                  onClick={archiveProject}>
                  Delete
                </Button>
              </div>
            </Dialog>
          </DialogBase>
        )}

        <div>
          {showReactions && (
            <div className={styles['reactions-container']}>
              <Reactions
                projectUuid={uuid as string}
                totalReactions={totalReactions || 0}
                userUuid={localUser?.uuid || ''}
                userReactedWith={userReactedWith as string}
                uniqueReactionTypes={uniqueReactionTypes as ReactionType[]}
              />
            </div>
          )}
        </div>
      </a>
    </div>
  )
}

export default memo(
  ProjectCard,
  (prevProps, nextProps) =>
    prevProps.uuid === nextProps.uuid &&
    prevProps.name === nextProps.name &&
    prevProps.showShareBtn === nextProps.showShareBtn &&
    prevProps.featured === nextProps.featured &&
    prevProps.public === nextProps.public &&
    prevProps.documentFrameSize === nextProps.documentFrameSize &&
    prevProps.userReactedWith === nextProps.userReactedWith &&
    prevProps.showReactions === nextProps.showReactions &&
    prevProps.totalReactions === nextProps.totalReactions &&
    prevProps.getCachedCardHeight === nextProps.getCachedCardHeight &&
    (prevProps.uniqueReactionTypes || []) ===
      (nextProps.uniqueReactionTypes || [])
)
