import React, { FC } from 'react'
import cn from 'classnames'
import styles from '@styles/components/ReactionsCount.module.scss'
import { ReactionType } from './Reactions'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  uniqueReactionTypes: ReactionType[]
  totalReactions: number
}

const ReactionCount: FC<Props> = ({
  uniqueReactionTypes,
  totalReactions,
  ...props
}) => {
  function formatReactionCount(count: number) {
    if (count >= 1000 && count < 1000000) {
      return `${Math.floor(count / 1000)}k+`
    } else if (count >= 1000000 && count < 1000000000) {
      return `${Math.floor(count / 1000000)}m+`
    } else if (count >= 1000000000) {
      return `1b+`
    } else {
      return count.toString()
    }
  }

  return (
    <div
      className={cn(styles['container'], {
        [styles['hidden']]: !totalReactions
      })}
      {...props}>
      {typeof totalReactions === 'number' && (
        <span className={styles['total']} data-testid="reactions-count">
          {formatReactionCount(totalReactions)}
        </span>
      )}
    </div>
  )
}

export default ReactionCount
