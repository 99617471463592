import { Project } from '@store/graphql/__generated__/schema'
import { Size } from 'types/size'

export const ProjectCardWidth = 260
export const ProjectCardSizeLimits: { min: Size; max: Size; median: Size } = {
  min: {
    height: 190,
    width: ProjectCardWidth
  },
  max: {
    height: 480,
    width: ProjectCardWidth
  },
  median: {
    height: 335,
    width: ProjectCardWidth
  }
}

function getDefaultHeightFromProjectUuid(uuid: string) {
  const randomDefaultSizes = [480, 190, 260, 320, 400]
  const hash = uuid.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
  const index = hash % randomDefaultSizes.length
  return randomDefaultSizes[index]
}

export const getProjectCardHeightFromDocumentFrameSize = (
  documentFrameSize: Project['documentFrameSize'],
  projectUuid: string
): number => {
  if (!documentFrameSize?.width || !documentFrameSize.height) {
    // ex: A project was created with its own document, not from template
    // Or for some reason, frame dimensions do not exist on the project (existing projects)
    return getDefaultHeightFromProjectUuid(projectUuid)
  }

  let { width, height } = documentFrameSize
  const aspectRatio = width / height

  if (width > ProjectCardSizeLimits.max.width) {
    width = ProjectCardSizeLimits.max.width
    height = width / aspectRatio
  }

  if (height > ProjectCardSizeLimits.max.height) {
    height = ProjectCardSizeLimits.max.height
    width = height * aspectRatio
  }

  if (width < ProjectCardSizeLimits.min.width) {
    width = ProjectCardSizeLimits.min.width
    height = width / aspectRatio
  }

  if (height < ProjectCardSizeLimits.min.height) {
    height = ProjectCardSizeLimits.min.height
    width = height * aspectRatio
  }

  if (height > ProjectCardSizeLimits.max.height) {
    height = ProjectCardSizeLimits.max.height
  }

  return Math.ceil(height)
}
