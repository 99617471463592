import React, { FC, useEffect, useRef } from 'react'
import { Avatar } from 'ui'
import styles from '@styles/components/ReactionsReactors.module.scss'

interface Props {
  onMouseEnter: () => void
  onMouseLeave: () => void
  showMoreReactions: () => void
  reactions: any
  nextOffset: number | null
}

const ReactionsReactors: FC<Props> = ({
  onMouseEnter,
  onMouseLeave,
  showMoreReactions,
  reactions,
  nextOffset
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0
    }
  }, [reactions])

  // Use to check if scrolled scrolled bottom element is visible to query more posts
  const bottom = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let observer: IntersectionObserver
    if (bottom.current && nextOffset) {
      observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          showMoreReactions()
        }
      })
      observer.observe(bottom.current)
    }

    return () => {
      observer?.disconnect()
    }
  }, [bottom.current, nextOffset])

  return (
    <div
      ref={containerRef}
      className={styles['container']}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <ul className={styles['list']}>
        {reactions.map(r => (
          <li key={r?.id} className={styles['list-item']}>
            <div className={styles['avatar-wrapper']}>
              <Avatar
                size={200}
                label={r?.adobeUserDisplayName || ''}
                src={r?.adobeUserAvatarUrl || ''}
              />
            </div>
            <span className={styles['list-item__user-name']}>
              {r?.adobeUserDisplayName}
            </span>
          </li>
        ))}
        <div className={styles['bottom-observer-wrapper']}>
          <div className={styles['bottom-observer']} ref={bottom}></div>
        </div>
      </ul>
    </div>
  )
}

export default ReactionsReactors
